import React from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { format } from 'date-fns';
import style from './style.css';

// Images
import Bronze from './images/Bronze.png';
import Silver from './images/Silver.png';
import Gold from './images/Gold.png';
import Info from './images/StarStatusInfo.png';
const Fortress = window.cccisd.fortress;

export default class StarStatus extends React.Component {
    static propTypes = {
        starStatus: PropTypes.string,
    };

    // This needs to be hooked up to flow completion progress
    getStarBackground = () => {
        const { starStatus } = this.props;
        if (starStatus === 'Bronze') {
            return Bronze;
        }
        if (starStatus === 'Silver') {
            return Silver;
        }
        if (starStatus === 'Gold') {
            return Gold;
        }
    };

    getStarText = () => {
        const { starStatus } = this.props;
        if (starStatus === 'Bronze') {
            return (
                <>
                    has hereby completed the School Mental Health Profile and has been counted in the National School
                    Mental Health Census.
                </>
            );
        }
        if (starStatus === 'Silver') {
            return (
                <>
                    has hereby completed the School Mental Health Profile and at least one domain of the School Mental
                    Health Quality Assessment.
                </>
            );
        }
        if (starStatus === 'Gold') {
            return (
                <>
                    has hereby completed the School Mental Health Profile and all domains of the School Mental Health
                    Quality Assessment.
                </>
            );
        }
    };

    render() {
        return (
            <Report downloadFilename="Shape_Certificate">
                <Page>
                    {this.props.starStatus !== 'None' ? (
                        <div
                            className={style.starBackground}
                            style={{
                                backgroundImage: `url(${this.getStarBackground()})`,
                            }}
                        >
                            <div className={style.content}>
                                <div className={style.contentUpper}>
                                    <div className={style.title}>
                                        {Fortress.user.acting.group && Fortress.user.acting.group.label}
                                    </div>
                                    <div className={style.upperText}>{this.getStarText()}</div>
                                </div>
                                <div className={style.contentLower}>
                                    <div style={{ display: 'flex', marginBottom: '0.5em' }}>
                                        <span>Presented by:</span>
                                        <div className={style.underline}>
                                            The National Center for School Mental Health
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <span>On this day:</span>
                                        <div className={style.underline}>
                                            {format(new Date(), 'dddd, MMMM Do, YYYY')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={style.starBackground}
                            style={{
                                backgroundImage: `url(${Info})`,
                            }}
                        />
                    )}
                </Page>
            </Report>
        );
    }
}
