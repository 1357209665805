import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import IconQuestion from 'cccisd-icons/question4';
import { connect } from 'react-redux';
import { getAggregateFlowData, saveDates, loadReportingPeriods } from 'js/reducers/shape.js';
import ReportModal from './ReportModal';

const mapStateToProps = (state, props) => {
    return {
        mhqFlowData: state.app.shape.mhqFlowData,
        trsFlowData: state.app.shape.trsFlowData,
        startDate: state.app.shape.startDate,
        endDate: state.app.shape.endDate,
    };
};

export default connect(
    mapStateToProps,
    { getAggregateFlowData, saveDates, loadReportingPeriods }
)(
    class AggregateReport extends React.Component {
        static propTypes = {
            title: PropTypes.string,
            flowType: PropTypes.string,
            childGroup: PropTypes.string,
            tooltip: PropTypes.string,
            reportTitle: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            saveDates: PropTypes.func,
            reportType: PropTypes.string,
            loadReportingPeriods: PropTypes.func,
        };

        beforeClose = async () => {
            await this.props.loadReportingPeriods();
        };

        render() {
            return (
                <div>
                    <Modal
                        trigger={
                            <button
                                type="button"
                                className="btn btn-primary btn-xs"
                                style={{ marginBottom: '0.5em' }}
                            >
                                {this.props.title}
                            </button>
                        }
                        title={this.props.title}
                        size="large"
                        beforeClose={this.beforeClose}
                    >
                        <ReportModal
                            reportType={this.props.reportType}
                            flowType={this.props.flowType}
                            pageTitle={this.props.reportTitle}
                            childGroup={this.props.childGroup}
                        />
                    </Modal>
                    <Tooltip title={this.props.tooltip}>
                        <IconQuestion spaceLeft />
                    </Tooltip>
                </div>
            );
        }
    }
);
