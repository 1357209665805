import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'cccisd-loader';
import { mhqSchoolFlows, mhqDistrictFlows, trsFlows } from 'js/selectors/flowData.js';
import { loadData, setTrsDataLoaded, setMhqDataLoaded, getAggregateFlowData } from 'js/reducers/shape.js';
import ReportForm from 'js/components/ReportForm';
import ShapeReport from 'js/components/ShapeReport';
import style from './style.css';

const mapStateToProps = (state, props) => {
    return {
        mhqFlowData: state.app.shape.mhqFlowData,
        trsFlowData: state.app.shape.trsFlowData,
        startDate: state.app.shape.startDate,
        endDate: state.app.shape.endDate,
        totalChildGroups: state.app.shape.totalChildGroups,
        reportingPeriods: state.app.shape.reportingPeriods,
    };
};

export default connect(mapStateToProps, {
    loadData,
    setTrsDataLoaded,
    setMhqDataLoaded,
    getAggregateFlowData,
})(
    class ReportModal extends React.Component {
        static propTypes = {
            endDate: PropTypes.string,
            startDate: PropTypes.string,
            trsFlowData: PropTypes.object,
            mhqFlowData: PropTypes.object,
            flowType: PropTypes.string,
            loadData: PropTypes.func,
            setTrsDataLoaded: PropTypes.func,
            setMhqDataLoaded: PropTypes.func,
            getAggregateFlowData: PropTypes.func,
            totalChildGroups: PropTypes.number,
            pageTitle: PropTypes.string,
            childGroup: PropTypes.string,
            reportType: PropTypes.string,
            reportingPeriods: PropTypes.array,
        };

        state = {
            loading: true,
        };

        componentDidMount = async () => {
            await this.props.loadData();
            await this.props.getAggregateFlowData({
                assessmentType: this.props.reportType,
                reportType: 'Average',
                timePeriods: null,
            });
            if (this.props.flowType === 'trs') {
                this.props.setTrsDataLoaded(false);
            } else {
                this.props.setMhqDataLoaded(false);
            }
            this.setState({ reportSettings: this.getInitialValues(), loading: false });
        };

        getGroupFlows = () => {
            const { flowType } = this.props;
            if (flowType === 'mhqDistrict') {
                return mhqDistrictFlows;
            }
            if (flowType === 'mhqSchool') {
                return mhqSchoolFlows;
            }
            if (flowType === 'trs') {
                return trsFlows;
            }
        };

        handleReportForm = async values => {
            // Values: {
            //  domain: array of integers from 1-15
            //  endDate: string "YYYY-MM"
            //  startDate: string "YYYY-MM"
            //  enteredBy: array of integer pawn ids
            //  reportType: string "Average" or "Last Completed"
            // }
            await this.props.getAggregateFlowData({
                assessmentType: this.props.reportType,
                reportType: values.reportType,
                timePeriods: values.timePeriods,
            });
            this.setState({ reportSettings: values });
        };

        getInitialValues = () => {
            const { startDate, endDate, flowType } = this.props;
            let domainOptions;
            if (flowType === 'trs') {
                domainOptions = [8, 9, 10, 11, 12, 13, 14, 15];
            } else {
                domainOptions = [1, 2, 3, 4, 5, 6, 7];
            }

            return {
                timePeriods: this.props.reportingPeriods.map(rp => rp.name),
                startDate,
                endDate,
                domain: domainOptions,
                enteredBy: [],
                reportType: 'Average',
            };
        };

        getSpgPages = () => {
            const { flowType } = this.props;
            if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
                return [1, 2, 3, 4, 5, 6];
            }

            if (this.props.flowType === 'trs') {
                return [1, 2, 3, 4];
            }

            return [1, 2, 3, 4, 5];
        };

        render() {
            const { flowType } = this.props;
            if (this.state.loading) {
                return <Loader />;
            }

            return (
                <>
                    <div className={style.reportForm}>
                        <ReportForm
                            flows={this.getGroupFlows()}
                            onSubmit={this.handleReportForm}
                            initialValues={this.getInitialValues()}
                            shapeFlowData={flowType === 'trs' ? this.props.trsFlowData : this.props.mhqFlowData}
                            flowType={this.props.flowType}
                            isAggregateReport
                        />
                    </div>
                    <div className={style.shapeReport}>
                        <ShapeReport
                            reportSettings={this.state.reportSettings}
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            spgPages={this.getSpgPages()}
                            flowType={this.props.flowType}
                            showSummaryReport
                            flows={this.getGroupFlows()}
                            isAggregateReport
                            totalChildGroups={this.props.totalChildGroups}
                            pageTitle={this.props.pageTitle}
                            childGroup={this.props.childGroup}
                        />
                    </div>
                </>
            );
        }
    }
);
