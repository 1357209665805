import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import IconQuestion from 'cccisd-icons/question4';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';
import { getStarStatus, getStateName, loadData } from 'js/reducers/shape.js';
import AggregateReport from '../AggregateReport';
import StarStatus from '../StarStatus';
import style from './style.css';

// images
import BronzeStar from './images/BronzeStar.png';
import SilverStar from './images/SilverStar.png';
import GoldStar from './images/GoldStar.png';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;
const userRole = Fortress.user.acting.role.handle;

const csvDownloadApprovedUserRoles = ['schoolAdmin', 'districtAdmin', 'stateAdmin', 'entityAdmin'];

const mapStateToProps = (state, props) => ({
    starStatus: state.app.shape.starStatus,
    stateName: state.app.shape.stateName,
    path: state.router.location.pathname,
});

export default connect(mapStateToProps, { getStarStatus, getStateName, loadData })(
    class PageHeader extends React.Component {
        static propTypes = {
            setFormInfo: PropTypes.func,
            getUserGroups: PropTypes.func,
            pageType: PropTypes.string,
            starStatus: PropTypes.string,
            getStarStatus: PropTypes.func,
            getStateName: PropTypes.func,
            stateName: PropTypes.string,
            path: PropTypes.string,
            loadData: PropTypes.func,
        };

        state = {
            loading: true,
        };

        componentDidMount = async () => {
            await this.props.loadData();
            if (!this.props.starStatus) {
                this.props.getStarStatus();
            }
            if (!this.props.stateName) {
                this.props.getStateName();
            }
            this.setState({ loading: false });
        };

        getPageTitle = () => {
            const { pageType } = this.props;
            if (Fortress.user.acting.group) {
                if (pageType === 'mhqSchool' || pageType === 'mhqDistrict' || pageType === 'trs') {
                    return Fortress.user.acting.group.label;
                }
            }

            if (!pageType && Fortress.user.acting.group) {
                if (Fortress.user.acting.group.data_type === 'state') {
                    if (this.props.path === '/myDistricts') {
                        return this.props.stateName + ' School Districts';
                    }
                    if (this.props.path === '/mySchools') {
                        return this.props.stateName + ' Schools';
                    }
                    return this.props.stateName;
                }
                return Fortress.user.acting.group.label;
            }
            return 'Individual Account';
        };

        getAggregateReportButtons = () => {
            const { path } = this.props;

            if (Fortress.user.acting.group) {
                if (Fortress.user.acting.group.data_type === 'state') {
                    if (path === '/mySchools') {
                        return (
                            <div className={style.reportButtons}>
                                <AggregateReport
                                    title="SMH-QA Statewide School Report"
                                    reportTitle="Quality Assessment Statewide School Report"
                                    flowType="mhqSchool"
                                    reportType="mhqSchool"
                                    childGroup="school"
                                    tooltip="This report includes an average of School Mental Health Quality Assessment scores across schools in your state/territory."
                                />
                                <AggregateReport
                                    title="TRS-IA Statewide School Report"
                                    reportTitle="Trauma Responsiveness Statewide School Report"
                                    flowType="trs"
                                    reportType="trsSchool"
                                    childGroup="school"
                                    tooltip="This report includes an average of The Trauma Responsive Schools Implementation Assessment (TRS-IA) scores across schools in your state/territory."
                                />
                            </div>
                        );
                    }
                    if (path === '/myDistricts') {
                        return (
                            <div className={style.reportButtons}>
                                <AggregateReport
                                    title="SMH-QA Statewide District Report"
                                    reportTitle="Quality Assessment Statewide District Report"
                                    flowType="mhqDistrict"
                                    reportType="mhqDistrict"
                                    childGroup="district"
                                    tooltip="This report includes an average of School Mental Health Quality Assessment scores across districts in your state/territory."
                                />
                                <AggregateReport
                                    title="TRS-IA Statewide District Report"
                                    reportTitle="Trauma Responsiveness Statewide District Report"
                                    flowType="trs"
                                    reportType="trsDistrict"
                                    childGroup="district"
                                    tooltip="This report includes an average of The Trauma Responsive Schools Implementation Assessment (TRS-IA) scores across district in your state/territory."
                                />
                            </div>
                        );
                    }
                }
                if (Fortress.user.acting.group.data_type === 'entity') {
                    if (path === '/mySchools') {
                        return (
                            <div className={style.reportButtons}>
                                <AggregateReport
                                    title="SMH-QA Entitywide School Report"
                                    reportTitle="Quality Assessment Entitywide School Report"
                                    flowType="mhqSchool"
                                    reportType="mhqSchool"
                                    childGroup="school"
                                    tooltip="This report includes an average of School Mental Health Quality Assessment scores across schools in your Entity."
                                />
                                <AggregateReport
                                    title="TRS-IA Entitywide School Report"
                                    reportTitle="Trauma Responsiveness Entitywide School Report"
                                    flowType="trs"
                                    reportType="trsSchool"
                                    childGroup="school"
                                    tooltip="This report includes an average of The Trauma Responsive Schools Implementation Assessment (TRS-IA) scores across schools in your Entity."
                                />
                            </div>
                        );
                    }
                    if (path === '/myDistricts') {
                        return (
                            <div className={style.reportButtons}>
                                <AggregateReport
                                    title="SMH-QA Entitywide District Report"
                                    reportTitle="Quality Assessment Entitywide District Report"
                                    flowType="mhqDistrict"
                                    reportType="mhqDistrict"
                                    childGroup="district"
                                    tooltip="This report includes an average of School Mental Health Quality Assessment scores across districts in your Entity."
                                />
                                <AggregateReport
                                    title="TRS-IA Entitywide District Report"
                                    reportTitle="Trauma Responsiveness Entitywide District Report"
                                    flowType="trs"
                                    reportType="trsDistrict"
                                    childGroup="district"
                                    tooltip="This report includes an average of The Trauma Responsive Schools Implementation Assessment (TRS-IA) scores across district in your Entity."
                                />
                            </div>
                        );
                    }
                }
                if (Fortress.user.acting.group.data_type === 'district') {
                    if (path === '/mySchools') {
                        return (
                            <div className={style.reportButtons}>
                                <AggregateReport
                                    title="SMH-QA Districtwide Report"
                                    reportTitle="Quality Assessment Districtwide School Report"
                                    flowType="mhqSchool"
                                    reportType="mhqSchool"
                                    childGroup="school"
                                    tooltip="This report includes an average of School Mental Health Quality Assessment scores across schools in your district."
                                />
                                <AggregateReport
                                    title="TRS-IA Districtwide Report"
                                    reportTitle="Trauma Responsiveness Districtwide School Report"
                                    flowType="trs"
                                    reportType="trsSchool"
                                    childGroup="school"
                                    tooltip="This report includes an average of The Trauma Responsive Schools Implementation Assessment (TRS-IA) scores across schools in your district."
                                />
                            </div>
                        );
                    }
                }
            }
        };

        _downloadCSVData = async assessmentType => {
            const url = Boilerplate.route('shape.filtered.raw.data') + '?assessmentType=' + assessmentType;
            const response = await axios.get(url);

            if (response.status === 200) {
                const link = document.createElement('a');
                link.href = Boilerplate.url(response.data.result.csvFile);
                link.setAttribute('download', assessmentType + '.csv');
                document.body.appendChild(link);
                link.click();
            }
        };

        _renderDownloadButton = (assessmentType, buttonText) => (
            <button
                type="button"
                className="btn btn-default btn-sm"
                onClick={() => this._downloadCSVData(assessmentType)}
                title="Download CSV Data"
            >
                <span className="glyphicon glyphicon-download-alt" /> {buttonText}
            </button>
        );

        _getCSVDownloadButtons = () => {
            const { path } = this.props;

            if (Fortress.user.acting.group) {
                if (Fortress.user.acting.group.data_type === 'state') {
                    if (path === '/mhProfile') {
                        return <>{this._renderDownloadButton('stateProfile', 'State/Territory Profile Data')}</>;
                    }
                    if (path === '/mySchools') {
                        return (
                            <div className={style.csvButtons}>
                                <div>{this._renderDownloadButton('mhqSchool', 'SMH-QA Statewide School Data')}</div>
                                <div>{this._renderDownloadButton('trsSchool', 'TRS-IA Statewide School Data')}</div>
                            </div>
                        );
                    }
                    if (path === '/myDistricts') {
                        return (
                            <div className={style.csvButtons}>
                                <div>{this._renderDownloadButton('mhqDistrict', 'SMH-QA Statewide District Data')}</div>
                                <div>{this._renderDownloadButton('trsDistrict', 'TRS-IA Statewide District Data')}</div>
                            </div>
                        );
                    }
                }

                if (Fortress.user.acting.group.data_type === 'entity') {
                    if (path === '/mhProfile') {
                        return <>{this._renderDownloadButton('entityProfile', 'Entity Profile Data')}</>;
                    }
                    if (path === '/mySchools') {
                        return (
                            <div className={style.csvButtons}>
                                <div>
                                    {this._renderDownloadButton('mhqEntitySchools', 'SMH-QA Entitywide School Data')}
                                </div>
                                <div>
                                    {this._renderDownloadButton('trsEntitySchools', 'TRS-IA Entitywide School Data')}
                                </div>
                            </div>
                        );
                    }
                    if (path === '/myDistricts') {
                        return (
                            <div className={style.csvButtons}>
                                <div>
                                    {this._renderDownloadButton(
                                        'mhqEntityDistricts',
                                        'SMH-QA Entitywide District Data'
                                    )}
                                </div>
                                <div>
                                    {this._renderDownloadButton(
                                        'trsEntityDistricts',
                                        'TRS-IA Entitywide District Data'
                                    )}
                                </div>
                            </div>
                        );
                    }
                    if (path === '/mentalHealthQuality') {
                        return (
                            <div>
                                <div>{this._renderDownloadButton('mhqEntity', 'Entity SMH-QA Data')}</div>
                            </div>
                        );
                    }
                    if (path === '/traumaResponsiveness') {
                        return (
                            <div>
                                <div>{this._renderDownloadButton('trsEntity', 'Entity TRS-IA Data')}</div>
                            </div>
                        );
                    }
                }

                if (Fortress.user.acting.group.data_type === 'district') {
                    if (path === '/mhProfile') {
                        return <>{this._renderDownloadButton('districtProfile', 'District Profile Data')}</>;
                    }
                    if (path === '/mySchools') {
                        return (
                            <div className={style.csvButtons}>
                                <div>{this._renderDownloadButton('mhqSchool', 'SMH-QA Districtwide School Data')}</div>
                                <div>{this._renderDownloadButton('trsSchool', 'TRS-IA Districtwide School Data')}</div>
                            </div>
                        );
                    }
                    if (path === '/mentalHealthQuality') {
                        return (
                            <div>
                                <div>{this._renderDownloadButton('mhqDistrict', 'District SMH-QA Data')}</div>
                            </div>
                        );
                    }
                    if (path === '/traumaResponsiveness') {
                        return (
                            <div>
                                <div>{this._renderDownloadButton('trsDistrict', 'District TRS-IA Data')}</div>
                            </div>
                        );
                    }
                }

                if (Fortress.user.acting.group.data_type === 'school') {
                    if (path === '/mhProfile') {
                        return <>{this._renderDownloadButton('schoolProfile', 'School Profile Data')}</>;
                    }
                    if (path === '/mentalHealthQuality') {
                        return (
                            <div>
                                <div>{this._renderDownloadButton('mhqSchool', 'School SMH-QA Data')}</div>
                            </div>
                        );
                    }
                    if (path === '/traumaResponsiveness') {
                        return (
                            <div>
                                <div>{this._renderDownloadButton('trsSchool', 'School TRS-IA Data')}</div>
                            </div>
                        );
                    }
                }
            }
        };

        getStarIcon = () => {
            const { starStatus } = this.props;

            if (starStatus === 'Gold') {
                return GoldStar;
            }
            if (starStatus === 'Silver') {
                return SilverStar;
            }
            if (starStatus === 'Bronze') {
                return BronzeStar;
            }
        };

        render() {
            const starIcon = this.getStarIcon();

            return (
                <Loader loading={this.state.loading}>
                    <div className={style.pageHeader}>
                        <div className={style.groupName}>
                            <h3>{this.getPageTitle()}</h3>
                            <div className={style.buttonsLeft}>
                                {this.getAggregateReportButtons()}
                                {csvDownloadApprovedUserRoles.includes(userRole) && this._getCSVDownloadButtons()}
                            </div>
                        </div>
                        <div className={style.headerRight}>
                            {Fortress.user.acting.group && Fortress.user.acting.group.data_type !== 'state' && (
                                <div className={style.starStatus}>
                                    <div className={style.starText}>
                                        <div style={{ marginBottom: '0.5em' }}>
                                            <span style={{ fontSize: '18px', fontWeight: '600' }}>My Star Status</span>
                                            <Tooltip title="Complete your School Mental Health Profile to earn Bronze. Complete all Quality Assessment domains to earn Gold.">
                                                <IconQuestion spaceLeft />
                                            </Tooltip>
                                        </div>

                                        <Modal
                                            trigger={
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ marginRight: '0.5em' }}
                                                >
                                                    {this.props.starStatus === 'None'
                                                        ? 'View Star Sheet'
                                                        : 'View Certificate'}
                                                </button>
                                            }
                                            title="Star Status"
                                            size="50vw"
                                        >
                                            <StarStatus starStatus={this.props.starStatus} />
                                        </Modal>
                                    </div>
                                    <div className={style.starIconBG} />
                                    <div className={style.starIcon} style={{ backgroundImage: `url(${starIcon})` }} />
                                </div>
                            )}
                        </div>
                    </div>
                </Loader>
            );
        }
    }
);
